import axios from 'axios';
import React, { useContext, useEffect } from 'react';
import { API_URL } from '../config';

const AUTH_TOKEN = 'auth';

interface User {
  id: number;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  companyName: string | null;
}

interface UpdateUser extends Partial<User> {
  newPassword?: string;
  password?: string;
}

interface AuthState {
  jwt?: string;
  user: User | null;
  loggedIn?: boolean;
}

interface Credentials {
  identifier: string;
  password: string;
}

interface SignupInfo {
  username: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  company: string;
}

const initialState: AuthState = (() => {
  try {
    const storedAuth = localStorage.getItem(AUTH_TOKEN);
    return (storedAuth && (JSON.parse(storedAuth) as AuthState)) || undefined;
  } catch {
    return undefined;
  }
})() || {
  jwt: undefined,
  user: null,
  loggedIn: false,
};

type AuthAction =
  | {
      type: 'login';
      payload: {
        jwt?: string;
        user: User | null;
      };
    }
  | { type: 'logout' }
  | {
      type: 'update';
      payload: User;
    };

const reducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'login':
      const { jwt = undefined, user = null } = action.payload;
      return { ...state, jwt, user, loggedIn: true };
    case 'update':
      return { ...state, user: action.payload };
    case 'logout':
      return { ...state, jwt: undefined, user: null, loggedIn: false };
    default:
      throw new Error('Invalid action');
  }
};

const defaultDispatch: React.Dispatch<AuthAction> = () => initialState;

const AuthContext = React.createContext({
  state: initialState,
  dispatch: defaultDispatch,
});

const AuthProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [state, dispatch] = React.useReducer<React.Reducer<AuthState, AuthAction>>(
    reducer,
    initialState,
  );
  useEffect(() => {
    if (state.loggedIn) {
      localStorage.setItem(AUTH_TOKEN, JSON.stringify(state));
    } else {
      localStorage.removeItem(AUTH_TOKEN);
    }
  }, [state]);
  return <AuthContext.Provider value={{ state, dispatch }}>{children}</AuthContext.Provider>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const wrapRootElement = ({ element }: { element: React.ReactNode }) => (
  <AuthProvider>{element}</AuthProvider>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getStrapiErrorMessage(res: any) {
  const errors = (res?.response?.data?.message && res?.response?.data?.message[0]?.messages) || [];
  return errors[0]?.message;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useAuth = () => {
  const { state, dispatch } = useContext(AuthContext);
  const isAuthenticated = state.loggedIn && state.user;

  const login = async (credentials: Credentials) => {
    try {
      const { data: payload } = await axios.post(`${API_URL}/auth/local`, credentials);
      dispatch({ type: 'login', payload });
      return payload;
    } catch (e) {
      throw getStrapiErrorMessage(e);
    }
  };

  const register = async (credentials: SignupInfo) => {
    try {
      const { data: payload } = await axios.post(`${API_URL}/auth/local/register`, credentials);
      dispatch({ type: 'login', payload });
      return payload;
    } catch (e) {
      throw getStrapiErrorMessage(e);
    }
  };

  const update = async (data: UpdateUser) => {
    try {
      const { data: payload } = await axios.put(`${API_URL}/users/me`, data, {
        headers: { authorization: `Bearer ${state.jwt ?? ''}` },
      });
      dispatch({ type: 'update', payload });
      return payload;
    } catch (e) {
      throw getStrapiErrorMessage(e);
    }
  };

  const forgotPassword = async ({ email }: { email: string }) => {
    try {
      await axios.post(`${API_URL}/users-custom-auth/forgot-password`, { email });
    } catch (e) {
      throw getStrapiErrorMessage(e);
    }
  };

  const resetPassword = async (data: {
    code: string;
    password: string;
    passwordConfirmation: string;
  }) => {
    try {
      await axios.post(`${API_URL}/auth/reset-password`, data);
    } catch (e) {
      throw getStrapiErrorMessage(e);
    }
  };

  const logout = () => {
    dispatch({ type: 'logout' });
  };

  return { state, isAuthenticated, login, logout, register, update, forgotPassword, resetPassword };
};

export default useAuth;
